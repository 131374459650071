import { gqlCreateItem, gqlDeleteItem, gqlGetItem, gqlRunAction, gqlUpdateItem } from '@/client/graphql'
import { Client } from '@/redux/actions/common/client'

const ResolutionLevel = 'FORM'

export const requestFormData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlGetItem()
    const response = await client?.query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
            input: { ...variables, entity: modelKey, resolutionLevel: ResolutionLevel }
        }
    })
    const data = response!.data[Object.keys(response!.data)[0]]
    return JSON.parse(data)
}

export const requestCreateEntityData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlCreateItem()
    const response = await client?.query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
            input: { item: JSON.stringify(variables.input), entity: modelKey }
        }
    })
    const data = response!.data[Object.keys(response!.data)[0]]
    return JSON.parse(data)
}
export const requestUpdateEntityData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlUpdateItem()
    const response = await client?.query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
            input: { item: JSON.stringify(variables.input), entity: modelKey }
        }
    })
    const data = response!.data[Object.keys(response!.data)[0]]
    return JSON.parse(data)
}
export const requestRunAction = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlRunAction()
    const response = await client?.query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
            input: { ...variables, entity: modelKey }
        }
    })
    const data = response!.data[Object.keys(response!.data)[0]]
    return JSON.parse(data)
}
export const requestDeleteEntityData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlDeleteItem()
    const response = await client?.query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
            input: { ...variables.input, entity: modelKey }
        }
    })
    const data = response!.data[Object.keys(response!.data)[0]]
    return JSON.parse(data)
}
