import React, { useCallback, useMemo } from 'react'
import format from 'date-fns-tz/format'
import objectPath from 'object-path'

import { Text } from '@/components/typography/Text'
import Button from '@/components/controls/Button'
import Checkbox from '@/components/controls/Checkbox'
import { Badge } from '@/components/blocks/Badge'
import { BadgeProps } from '@/components/blocks/Badge/types'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'

import ImageCell from '../ImageCell'
import { ImageCellPreviewSize, ImageCellType } from '../ImageCell/types'

type StringProps = { value: string }
const String: React.FC<StringProps> = ({ value }) => (
  <Text size='xs' title={value}>
    {value}
  </Text>
)

type LinkProps = { value: string }
const Link: React.FC<LinkProps> = ({ value }) => {
  const handleOpenLink = useCallback(() => window && window.open(value, '_blank'), [value])
  return (
    <Button size='sm' styleType='link' onClick={handleOpenLink}>
      <Text size='xs' title={value}>
        {value}
      </Text>
    </Button>
  )
}

type BooleanProps = { value: boolean }
const Boolean: React.FC<BooleanProps> = ({ value }) => <Checkbox size='md' selected={value} />

type RelationProps = { options: { value: string; color?: string }[] }
const DEFAULT_BADGE_PROPS = { styleType: 'light' } as Partial<BadgeProps>
const Relation: React.FC<RelationProps> = ({ options }) => (
  <>
    {options.map(({ value, color }) => (
      <Badge key={value} background={color} {...(!color ? DEFAULT_BADGE_PROPS : {})}>
        <Text size='xs'>{value}</Text>
      </Badge>
    ))}
  </>
)
const getObjectValue = (item: Record<string, any>) => {
  const { key } = TITLE_GETTER_BY_MODEL_KEY[item.__type!] ?? {}
  return typeof item[key] === 'string' ? item[key] : 'Object'
}
const getClassValue = (item: Record<string, any>, modelKey: string) => {
  const { key } = TITLE_GETTER_BY_MODEL_KEY[modelKey] ?? {}
  return typeof item[key] === 'string' ? item[key] : 'Object'
}

export type DataCellType =
  | 'string'
  | 'json'
  | 'link'
  | 'boolean'
  | 'image'
  | 'relation'
  | 'timestamp'
  | 'list'
  | 'object'
  | 'class'
export type DataCellColors = Record<string, string>
type DataCell = {
  type?: DataCellType
  modelKey?: string
  relationFormat?: 'enum' | 'type'
  previewSize?: ImageCellPreviewSize
  imageType?: ImageCellType
  colors?: DataCellColors
  labelPath?: string
  getUrl?: (id: string) => string | undefined
}
export type DataCellValues = DataCell & { config?: DataCell }
type DataCellProps = DataCellValues & { row: any; selector: string }

const DataCell: React.FC<DataCellProps> = ({ selector, row, ...props }) => {
  const cellValue = useMemo(() => row[selector], [row, selector])

  const renderCellByType = (
    value: any,
    {
      type,
      config,
      modelKey,
      relationFormat,
      imageType = 'table',
      previewSize = 'Small',
      colors = {},
      labelPath,
      getUrl
    }: DataCellValues
  ): React.ReactNode => {
    if (type === 'string') {
      return <String value={value} />
    }
    if (type === 'json' && labelPath) {
      return <String value={objectPath.get(value, labelPath)} />
    }
    if (type === 'json' && !labelPath) {
      return <String value={JSON.stringify(value)} />
    }
    if (type === 'timestamp') {
      let dateString = ''

      if (value && value.toString().length === 10) {
        dateString = format(new Date(value * 1000), 'dd MMM yyyy, HH:mm:ss (z)')
      }
      if (value && value.toString().length === 13) {
        dateString = format(new Date(value), 'dd MMM yyyy, HH:mm:ss (z)')
      }
      return <String value={dateString} />
    }
    if (type === 'image') {
      return <ImageCell imageType={imageType} value={value} previewSize={previewSize} getUrl={getUrl} />
    }
    if (type === 'link') {
      return <Link value={value} />
    }
    if (type === 'boolean') {
      return <Boolean value={value} />
    }
    if (type === 'class') {
      if (!value) {
        return <Relation options={[]} />
      }
      return <Relation options={[{ value: getClassValue(value, modelKey!), color: colors[value] }]} />
    }
    if (type === 'object') {
      if (!value) {
        return <Relation options={[]} />
      }
      const options = Array.isArray(value)
        ? value.map((item) => ({ value: getObjectValue(item), color: colors[item] }))
        : [{ value: getObjectValue(value), color: colors[value] }]
      return <Relation options={options} />
    }
    if (type === 'list') {
      if (config?.type === 'string' && Array.isArray(value)) {
        return <String value={value.join(', ')} />
      }
      if (config?.type === 'object') {
        if (!value) {
          return <Relation options={[]} />
        }

        const options = Array.isArray(value)
          ? value.map((item) => ({ value: getObjectValue(item), color: colors[item] }))
          : [{ value: getObjectValue(value), color: colors[value] }]
        return <Relation options={options} />
      }
      return renderCellByType(value, config as DataCellValues)
    }
    if (type === 'relation') {
      if (!value) {
        return <Relation options={[]} />
      }
      const isArray = Array.isArray(value)
      if (relationFormat === 'enum') {
        const options = isArray
          ? value.map((item) => ({ value: item, color: colors[item] }))
          : [{ value, color: colors[value] }]
        return <Relation options={options} />
      }
      if (relationFormat === 'type') {
        const { key, getValue } = TITLE_GETTER_BY_MODEL_KEY[modelKey!]
        const options = isArray
          ? value.map((item) => ({ value: getValue(item[key]) }))
          : [{ value: getValue(value[key]) }]
        return <Relation options={options} />
      }
      return null
    }

    return null
  }

  return <>{renderCellByType(cellValue, props)}</>
}

DataCell.defaultProps = {
  type: undefined,
  config: undefined,
  modelKey: undefined,
  relationFormat: undefined,
  previewSize: 'Small',
  getUrl: undefined,
  labelPath: undefined,
  colors: {},
  imageType: 'table'
}

export default React.memo(DataCell)
