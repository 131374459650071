import * as ast from 'graphql/language/ast'
import gql from 'graphql-tag'

export function gqlGetItem(): ast.DocumentNode {
    return gql`
        query get($input: GetInput!) {
            get(input: $input)
        }
    `
}

export function gqlListItems(index?: string): ast.DocumentNode {
    return index
        ? gql`
              query index($input: IndexInput!) {
                  index(input: $input) {
                      items
                      nextToken
                  }
              }
          `
        : gql`
              query list($input: ListInput!) {
                  list(input: $input) {
                      items
                      nextToken
                  }
              }
          `
}

export function gqlFindItems(): ast.DocumentNode {
    return gql`
        query find($input: FindInput!) {
            find(input: $input) {
                items
                nextToken
            }
        }
    `
}

export function gqlCreateItem(): ast.DocumentNode {
    return gql`
        mutation create($input: CreateInput!) {
            create(input: $input)
        }
    `
}

export function gqlUpdateItem(): ast.DocumentNode {
    return gql`
        mutation update($input: UpdateInput!) {
            update(input: $input)
        }
    `
}

export function gqlDeleteItem(): ast.DocumentNode {
    return gql`
        mutation delete($input: DeleteInput!) {
            delete(input: $input)
        }
    `
}

export function gqlRunAction(): ast.DocumentNode {
    return gql`
        mutation runAction($input: RunActionInput!) {
            runAction(input: $input)
        }
    `
}

export function gqlOnChangeItem(): ast.DocumentNode {
    return gql`
        subscription onChange($entity: String!) {
            onChange(entity: $entity) {
                id
                change
                before
                after
                entity
            }
        }
    `
}
