import { gqlListItems } from '@/client/graphql'
import { Client } from '@/redux/actions/common/client'

const ResolutionLevel = 'ROW'

type Response = { items: any[]; nextToken: string | null }
export const requestTableData = async (
    modelKey: string,
    client: Client,
    variables: Record<string, any>,
    index?: string
) => {
    const query = gqlListItems(index)
    const response = await client?.query<{ [key: string]: Response }>({
        fetchPolicy: 'no-cache',
        query,
        variables: { input: { ...variables, entity: modelKey, resolutionLevel: ResolutionLevel } }
    })
    const { items, nextToken } = response!.data[Object.keys(response!.data)[0]]
    return { items: items.map((i) => JSON.parse(i)), nextToken }
}
