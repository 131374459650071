import React, { useMemo } from 'react'
import { useSubscription } from '@apollo/client'

import { gqlOnChangeItem } from '@/client/graphql'

import { useAppDispatch } from '@/redux/store'
import { setSubscriptionDataAction } from '@/redux/actions/subscription'

const ResolutionLevel = 'ROW'

type Props = { modelKey: string }
const Subscription: React.FC<Props> = ({ modelKey }) => {
  const dispatch = useAppDispatch()
  const subscription = useMemo(() => gqlOnChangeItem(), [modelKey])
  useSubscription(subscription, {
    variables: { entity: modelKey, resolutionLevel: ResolutionLevel },
    onData: ({ data: { data } }) => {
      const { id, change, before, after, entity } = data[Object.keys(data)[0]] as {
        id: string
        change: string
        before: any
        after: any
        entity: string
      }
      dispatch(
        setSubscriptionDataAction({
          modelKey,
          response: {
            id,
            change,
            before: before ? JSON.parse(before) : null,
            after: after ? JSON.parse(after) : null,
            entity
          }
        })
      )
    }
  })
  return null
}

export default Subscription
