import { schema } from '@/helpers/schema'
import { Field, FieldArrayLike, ModelType, TypeObject } from '@/client/schema'

const transformValue = (value: any, field: FieldArrayLike): any => {
    if (Array.isArray(value)) {
        return value.map((item) => transformValue(item, field))
    }
    if (value && typeof value === 'object') {
        const modelKey = value.__type
        const model = (field?.of as TypeObject)?.__refs?.find((ref: any) => ref.model.__key === modelKey)
            ?.model as ModelType
        if (model) {
            const transformed: any = Object.fromEntries(
                Object.entries(value).map(([key, val]) => {
                    const subField = model.fields?.[key]
                    return [key, transformValue(val, subField as FieldArrayLike)]
                })
            )

            const result: any = Object.fromEntries(
                Object.entries(transformed).map(([key, val]) => {
                    const subField = model.fields?.[key]
                    if (subField?.__kind === 'RefType') {
                        return [`${key}Id`, (val as any)?.id]
                    }
                    if (subField?.__kind === 'ArrayLikeOfRefType') {
                        return [
                            `${key}Ids`,
                            (val as any)?.map((item: any) =>
                                item && typeof item === 'object' && 'id' in item ? item.id : item
                            )
                        ]
                    }
                    return [key, val]
                })
            )
            return result
        }
    }
    return value
}

type GetFieldMappedValue = (
    field: Field,
    selector: string,
    values: Record<string, any>
) => { field: string; value: any } | undefined
const getFieldValue: GetFieldMappedValue = (field: Field, selector: string, values: Record<string, any>) => {
    const value = values[selector]

    if (field.type === 'String' && value === '') {
        return { field: selector, value: null }
    }
    if (field.type === 'Int' && value !== undefined) {
        return { field: selector, value: value ? Number.parseInt(value, 10) : null }
    }
    if (field.type === 'Timestamp') {
        if (!value) {
            return { field: selector, value: null }
        }
        const fieldValue =
            typeof value === 'number' ? value : Math.round(field.isMs ? value.getTime() : value.getTime() / 1000)
        return { field: selector, value: fieldValue }
    }
    if (field.type === 'Float' && value !== undefined) {
        return { field: selector, value: value ? Number.parseFloat(value) : null }
    }
    if (field.type === 'Ref' && field.__kind === 'RefType') {
        return { field: `${selector}Id`, value: value ? value.id : null }
    }
    if ((field.type === 'Set' || field.type === 'List') && field.__kind === 'ArrayLikeOfRefEnum') {
        const fieldValue = value && Array.isArray(value) && value.length > 0 ? value : null
        return { field: selector, value: fieldValue }
    }
    if ((field.type === 'Set' || field.type === 'List') && field.__kind === 'ArrayLikeOfRefType') {
        const fieldValue = value ? value.map((item: { id: string }) => item.id) : null
        return { field: `${selector}Ids`, value: fieldValue }
    }
    if (field.type === 'Object') {
        return { field: selector, value }
    }
    if (field.type === 'Class') {
        return { field: selector, value }
    }
    if ((field.type === 'Set' || field.type === 'List') && field.__kind === 'ArrayLikeOfObject') {
        const fieldValue = Array.isArray(value) ? transformValue(value, field) : value
        return { field: selector, value: fieldValue }
    }
    if (!value && typeof value !== 'boolean') {
        return { field: selector, value: null }
    }

    return { field: selector, value }
}

export const getMappedValuesByModelKey = (modelKey: string, values: Record<string, any>, update = false) => {
    const model = schema.models[modelKey] as ModelType
    const fieldsValidation = Object.keys(model.fields).reduce((accumulator, selector) => {
        const field = model.fields[selector]
        if (
            field.external ||
            field.type === 'Formula' ||
            field.type === 'Index' ||
            (update && field.readonly) ||
            (!update && field.type === 'ID' && field.formula)
        ) {
            return accumulator
        }
        const mappedValue = getFieldValue(field, selector, values)
        if (mappedValue) {
            return { ...accumulator, [mappedValue.field]: mappedValue.value }
        }
        return accumulator
    }, {})

    return fieldsValidation
}
