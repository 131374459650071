import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import { JsonEditorProps } from '@/components/controls/JsonEditor'
import { Placeholder } from '@/components/blocks/Placeholder'

const JsonEditor = React.lazy(() => import(/* webpackChunkName: 'json-editor' */ '@/components/controls/JsonEditor'))

interface JsonEditorFieldProps extends JsonEditorProps {
  name: string
}

const JsonEditorField: React.FC<JsonEditorFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<string | null>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)

      const handleChange = (value: string | null) => {
        form.setFieldValue(field.name, value)
      }
      const handleError = (errorMessage: string) => {
        const errors = { ...error }
        if (errorMessage) {
          errors.Syntax = errorMessage
        } else {
          delete errors.Syntax
        }
        if (Object.keys(errors).length > 0) {
          form.setFieldError(field.name, errors)
        } else {
          form.setFieldError(field.name, undefined)
        }
      }

      return (
        <React.Suspense fallback={<Placeholder height='200px' />}>
          <JsonEditor
            {...field}
            {...props}
            value={JSON.stringify(field.value || '')}
            error={error}
            disabled={isSubmitting || disabled}
            onChange={handleChange}
            onError={handleError}
          />
        </React.Suspense>
      )
    }}
  </Field>
)

export default JsonEditorField
