import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'

export type ClientKey = 'iam' | 'cognito'
export type Client = ApolloClient<NormalizedCacheObject> | null

export const CLIENT_ACTION = 'CLIENT'
export const SET_CLIENT_ACTION = `${CLIENT_ACTION}/SET_CLIENT`

export const requestClientAction = createAsyncThunk(CLIENT_ACTION, async () => {
    try {
        await Auth.currentAuthenticatedUser()
        return 'cognito'
    } catch (e) {
        return 'iam'
    }
})

export const setClientAction = createAction<ClientKey>(SET_CLIENT_ACTION)

export const setCognitoClientAction = () => setClientAction('cognito')
export const setIamClientAction = () => setClientAction('iam')
